.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 4rem;
    transition: margin-left .2s;
}

.layout-main {
  
    flex: 1 1 auto;
      @media (min-width: 900px){
padding-left: 2.5rem;
      }
    
}