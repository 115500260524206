.doughnut-inline{
    display: flex;
    justify-content: space-around;
}
/* rgb(64, 141, 61) */
.doughnut{
    display: flex;
    justify-content: space-around;
}

.item-center{
    display: flex;
    justify-content: center;
}
.height-chart{
    height: 1%;
}

@media (max-width: 900px)
{
    .doughnut{
        .doughnut{
            display: flex;
            justify-content: space-around;
        }
    }
    .martop{
        margin-top: 80px;
    }
    /* .text-small{
       font-size: 10px;
    } */
}
.bg-1 {
    background: linear-gradient(83deg, #736DFF, #A97FFF);
}

.bg-2 {
    background: linear-gradient(83deg, #44DEC5, #4EBCFA);
}

.bg-3 {
    background: linear-gradient(83deg, #DEB544, #D5C896);
}

.bg-4 {
    background: linear-gradient(83deg, #F7C598, #FF8886);
}

.bg-5 {
    background: linear-gradient(83deg, #F9B4BD, #9573DB);
}